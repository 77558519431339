import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Loevenich: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Loevenich,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Lövenich"
          d="M160.8,579.7c0.1-0.2,0.4-0.8,0.3-1.1c-0.3-0.1-1.4-0.4-1.6-0.6c1.6-1.7,11.5-11.2,13.4-13 c1.9-1.2,9.4-5.4,11.2-6.5c-0.4-0.5-1.1-1.8-1.4-2.4l0.3-0.1l10,14l22-8l6,8l22-10l8,8l0.9,45l-95.2,12.8 c-5.7-3.9-6.6-11.5-7.8-17.8c-1.7-3.2-5.5-10.3-7.3-13.6l8.4,1.9c0.2-0.4,11.7-15.9,11.9-16.3C161.7,579.9,161,579.8,160.8,579.7z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 176.6667 600.1332)"
        >
          Lövenich
        </text>
      </g>
    </>
  );
});
